var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h3", { staticClass: "mb-2" }, [_vm._v("Debt")]),
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c(
            "v-card-text",
            [
              _vm.debts.length
                ? _c(
                    "v-row",
                    { staticClass: "justify-center" },
                    [
                      _vm._l(_vm.debts, function(ref, index) {
                        var icon = ref.icon
                        var label = ref.label
                        var amount = ref.amount
                        return [
                          _c("v-col", { key: index, attrs: { cols: "3" } }, [
                            _c(
                              "div",
                              { staticClass: "d-flex align-center" },
                              [
                                _c(
                                  "v-avatar",
                                  {
                                    staticClass: "mr-3",
                                    attrs: { size: "100", color: "primary" }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { size: "80", color: "white" } },
                                      [_vm._v(_vm._s(icon))]
                                    )
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "text-center" }, [
                                  _c("h1", { staticClass: "mb-2" }, [
                                    _vm._v(_vm._s(amount))
                                  ]),
                                  _c(
                                    "h3",
                                    {
                                      staticClass:
                                        "font-weight-regular text-uppercase"
                                    },
                                    [_vm._v(" " + _vm._s(label) + " ")]
                                  )
                                ])
                              ],
                              1
                            )
                          ])
                        ]
                      })
                    ],
                    2
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }