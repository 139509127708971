<template>
    <div>
        <h3 class="mb-2">Debt</h3>
        <v-card flat>
            <v-card-text>
                <v-row v-if="debts.length" class="justify-center">
                    <template v-for="({ icon, label, amount }, index) in debts">
                        <v-col :key="index" cols="3">
                            <div class="d-flex align-center">
                                <v-avatar
                                    size="100"
                                    color="primary"
                                    class="mr-3"
                                >
                                    <v-icon size="80" color="white">{{
                                        icon
                                    }}</v-icon>
                                </v-avatar>
                                <div class="text-center">
                                    <h1 class="mb-2">{{ amount }}</h1>
                                    <h3
                                        class="font-weight-regular text-uppercase"
                                    >
                                        {{ label }}
                                    </h3>
                                </div>
                            </div>
                        </v-col>
                    </template>
                </v-row>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
export default {
    name: "credit-debt",
    components: {},
    props: {
        creditBalances: {
            type: Object,
            required: true,
        },
        publicRecords: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        debts() {
            let debts = Object.keys(this.creditBalances).map((key) => {
                return {
                    label: key,
                    icon: this.getIcon(key),
                    amount: this.$dollarFormat(this.creditBalances[key]),
                };
            });

            if (this.publicRecords.length > 0) {
                const amount = this.publicRecords.reduce((sum, item) => {
                    const itemAmount = parseInt(
                        this.$_.get(item, "liabilitiesAmount", 0)
                    );
                    return (sum += isNaN(itemAmount) ? 0 : itemAmount);
                }, 0);
                debts.push({
                    label: "Judgements Debt",
                    amount: amount > 0 ? this.$dollarFormat(0) : "N/A",
                    icon: "mdi-scale-balance",
                });
            }
            return debts;
        },
    },
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {
        getIcon(key) {
            switch (key) {
                case "Utility Debt":
                    return "mdi-transmission-tower";
                case "Property Debt":
                    return "mdi-home-city";
                case "Student Loan Debt":
                    return "mdi-school";
                case "Medical Debt":
                    return "mdi-medical-bag";
                case "Uncategorized":
                    return "mdi-help-circle";
                default:
                    return "mdi-alert";
            }
        },
    },
};
</script>

<style scoped>
</style>